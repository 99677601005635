import algoliasearch from "algoliasearch/lite"

const APPLICATION_ID = "314ANM0H0B"
const SEARCH_API_KEY = "8e9e88600183e9f016683847eaf6ce38"
const ALGOLIA_INDEX = "aspirantes_uninorte"

const client = algoliasearch(APPLICATION_ID, SEARCH_API_KEY)

const index = client.initIndex(ALGOLIA_INDEX)

export default index
